<template>
  <v-container>
    <v-card>
      <v-toolbar
        dense
        color="primary"
        dark>
        Confirmar pedido
        <v-spacer></v-spacer>

        <v-btn small :loading="loading" @click="saveinfo" color="success">Procesar</v-btn>
      </v-toolbar>
      <v-row class="mt-2 mx-2">
        <v-col cols="12">
       
          <p>Nombre: {{ nomuser }}  Email: {{email}}</p>

          <v-file-input
            click:clear='limpiarfoto'
            outlined
            dense
            small-chips
            v-model="files"
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            hint="Sube un archivo con el comprobante"
            label="Sube el comprobante"
            @change="cargar()"
          ></v-file-input>
        </v-col>
      </v-row>

     <v-dialog
        transition="dialog-bottom-transition"
        max-height="1200"
        max-width="900"
      >

        <template v-slot:activator="{ on, attrs }">
           <v-col cols="12">
          <v-card
            v-bind="attrs"
            v-on="on"   >
            <v-img :src="logo" contain max-height="1200" ></v-img>
          </v-card>
        </v-col>

         <!--  <v-btn
            class="ma-2"
            color="primary"
            v-bind="attrs"
            v-on="on"
          >Ver en Grande</v-btn> -->
        </template>

        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
              dense
            >Recibo de pago</v-toolbar>

            <v-card-text>
              <!-- <div class="text-h2 pa-12">Hello world!</div> -->
              <v-card @click="mostrarZoom">
                <v-img :src="logo" contain max-height="1200" max-width="1200" ></v-img>
              </v-card>

            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    </v-card>

  </v-container>
</template>

<script>

import axios from 'axios'
import { mapGetters } from 'vuex'
// import cerrar from './cerrar.vue'

export default {

  // components: { cerrar },
  data () {
    return {
      email: "",
      nomuser:'',

      email1:'',
      email2:'',
      loading: false,
      iddocum: '',
      numdoc: '',
      fecha:'',
      total:0.0,
      divisa:'',
      nombreImagen:'',

      ctadeps: '',
      nModo: 1,
      logo: '',
      logourl: '',
      fotopago1: '',

      mostrarZoom:'',
      fileLogo: '',
      files: [],
      imagen1: '',

      rules: [
        value => !value || value.size < 200000 || 'El logo debe medir menos de 200KB !'
      ]
    }
  },
  created () {
    // console.log("params", this.$route.params)
    this.fotopago1 = this.$route.params.info.fotopago1

    if (this.$route.params.info.statusped == 0) {
      this.nModo = 1

      this.$http.get('api/v1/logos.list').then(response => {
        this.logo = response.body.Logourl
        this.logourl = response.body.Logourl

        // Mostramos foto actual si la imagen existe
        if (this.fotopago1 != '') {
          this.logo = this.fotopago1
        }
      }, error => {
        console.log(error)
      })
    } else {
      this.nModo = 2
    }

    this.cargarInfo()
  },

  computed: {
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('documento', ['getDocumento']),
    ...mapGetters('Login', ['getdatosUsuario', 'getCIA', 'getCliente'])
  },

  methods: {

    cargarInfo () {
      console.log("getdocumento",this.getDocumento)
      // console.log("getdatosUsuario",this.getdatosUsuario)
      
      this.email = this.getdatosUsuario.Email
      this.nomuser = this.getdatosUsuario.Nomuser
      this.iddocum = this.getDocumento.iddocum
      this.numdoc = this.getDocumento.numdoc
      this.fecha = this.getDocumento.fecha
      this.total = this.getDocumento.total
      this.logourl = this.getLogourl
      this.divisa = this.getDocumento.divisa


      this.$http.get('api/v1/email.list').then(response => {
        // console.log(response)
        this.email1 = response.body.Email1
        this.email2 = response.body.Email2
      }).catch(err => { console.log(err) })

      this.$http.get('auth/api/v1/formatos').then(response => {
        // console.log(response)
        this.ctadeps = response.data.Ctadeps
        // this.email2 = response.body.Email2
      }).catch(err => { console.log(err) })

      this.fotopago1 = this.$route.params.info.fotopago1
      // console.log('modo', this.nModo)
      // console.log('fotopago1', this.fotopago1)

      if (this.nModo == 2) {
        // VUE_APP_OPTIONS_ROOT=https://saitmd3.com/tienda3
        // let cUrlWeb = process.env.VUE_APP_OPTIONS_ROOT

        // let cUrlWeb ="https://saiterp.com/cosmeticoszalie/tienda3/"
        // let urlpago1 = cUrlWeb.replace("tienda3/", "fotossait/");
        // // Hacemos un ciclo para cargar las imagenes
        this.logo = this.fotopago1
        console.log('logo src', this.fotopago1)
      }
    },

    limpiarfoto () {
      console.log("limpiarfoto", this.logourl)
      this.logo = this.logourl
    },

    cargar () {
      // console.log(this.files)
      if (this.files !== null) {
        // console.log("hola")
  	      this.getBase64(this.files)
      }

      this.logo = this.logourl

	    },

    getBase64 (file) {
      var me = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        me.imagen1 = reader.result
        me.logo = reader.result
        // console.log(reader.result);
      }
      this.nombreImagen = this.files.name
    },

    subirfoto () {
      console.log(this.files)
      this.nombreImagen = this.files.name
      this.size = this.files.size
      this.getBase64(this.files)
      // this.subirimg(this.files)
    },

    saveinfo () {
    	// this.subirimg()
     	this.loading = true
     	// console.log('name file', this.files.name)

     	const formDataLogo = new FormData()
      // se crea el objeto y se le agrega como un apendice el archivo
      formDataLogo.append('file', this.files)
      // console.log(formDataLogo)
      // console.log("nombreImagen", this.nombreImagen)


     	// SUBIR LA IMAGEN AL SERVIDOR
      this.$http.post('auth/api/v1/imagenes', formDataLogo, { headers: { 'Content-Type': 'multipart/form-data' } }).then(function (response) {
        console.log('Se cargó la imagen PONER SNACK BAR ', response)

        this.$router.push({ name: 'segped' })
      }).catch(function (error) {
          
  			  console.log(error)
      })

      this.cambiarstatus()
      this.sendmail()
    },

    sendmail(){

      if (this.getDocumento.divisa == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      // Corificicar iddocum
      const iddocumcifrado = base64.encode(this.iddocum)
      const url = this.$http.options.root.replace('tienda3', 'consultacoti/')
        
      const urlweb = this.$http.options.root.replace('http://localhost:8099/', 'https://grupoazalie.com/')
      const urlfotopago1 = urlweb.replace('tienda3', 'fotossait/'+ this.nombreImagen)
        

      this.loading = true
      const payload = {
        subject: "Anticipo Pedido "+ this.numdoc,

        email: this.email,
        email1: this.email1,
        email2: this.email2,

        nomuser: this.nomuser,
        logo: this.logourl,
        c2a_link: url + iddocumcifrado,
        
        numdoc: this.numdoc,
        ctadeps: this.ctadeps,
        fecha: this.fecha,
        total: this.total,
        divisa: this.divisa,
        nomcli: this.getCliente.nomcli,
        layout: "pagar1.html",
        fotolink: urlfotopago1,
      }
      
      console.log('Sendamil payload', payload)
      this.$http.post('auth/api/v1/sendmail6', payload).then(response => {
        console.log('enviando Correo sendmail6', response)
        this.loading= false
      }).catch(error => { console.log(error) })

    },

    cambiarstatus () {
      // API AXIOS api/v2
      var url = process.env.VUE_APP_OPTIONS_ROOT.replace('/tienda3', '/api/v2/')
      var uri = url + 'seg.fotopago1'
      console.log('url completa', uri)

      const payload = {
        iddocum: this.getDocumento.iddocum,
        fotopago1: this.files.name.toLowerCase()
      }

      console.log('Cambiar status payload', payload)
      this.loading = true
      const token = localStorage.tlkey
      axios.put(uri, payload, { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${token}` } }).then(response => {
        console.log('Response cambiar statusped')
        // this.$router.push({name: 'segped'})
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    }

  }
}
</script>
